<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="mb-3 d-flex justify-content-between">
            <h3>Create a Medical Request</h3>
            <b-btn variant="primary" :to="{ name: 'All Medical Requests' }">
              <i class="fa fa-arrow-left mr-1"></i>
              Back to Medical Requests
            </b-btn>
          </div>

          <div class="widget-content widget-content-area">
            <form
              method="POST"
              @submit.prevent="storePrescription({ forceSave: false })"
              @keydown="prescriptionForm.onKeydown($event)"
            >
              <AlertErrors :form="prescriptionForm" />

              <div class="form-group">
                <label for="">Choose the Patient</label>
                <v-select
                  v-model="selectedPatient"
                  :options="patients"
                  label="fullname"
                  :filterable="false"
                  @search="vselectSearch"
                >
                  <template #no-options> type to search patients... </template>

                  <template #list-header v-if="vselectSearchResults">
                    <div
                      class="bg-dark border-bottom p-3 d-flex justify-content-between"
                    >
                      <span class="small">
                        Total: {{ vselectSearchResults.total }}<br />
                        Pages: {{ vselectSearchResults.last_page }}<br />
                        Current Page: {{ vselectSearchResults.current_page }}
                      </span>
                      <div class="mb-2">
                        <b-btn
                          :disabled="!vselectSearchResults.prev_page_url"
                          @click="
                            vselectGoToPage(
                              vselectSearchResults.current_page - 1
                            )
                          "
                          variant="info"
                          class="mr-2"
                          size="sm"
                          squared
                        >
                          &laquo; Prev
                        </b-btn>
                        <b-btn
                          :disabled="!vselectSearchResults.next_page_url"
                          @click="
                            vselectGoToPage(
                              vselectSearchResults.current_page + 1
                            )
                          "
                          variant="success"
                          size="sm"
                          squared
                        >
                          Next &raquo;
                        </b-btn>
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>

              <div class="" v-if="selectedPatient">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tr>
                      <th width="20%">Doctor Name</th>
                      <td>
                        {{
                          `${selectedPatient.doctor.firstname} ${selectedPatient.doctor.lastname}`
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Doctor Phone</th>
                      <td>
                        {{ selectedPatient.doctor.phone }}
                      </td>
                    </tr>
                    <tr>
                      <th>Doctor Email</th>
                      <td>
                        {{ selectedPatient.doctor.email }}
                      </td>
                    </tr>
                    <tr>
                      <th>Sponsor Name</th>
                      <td>
                        {{ selectedPatient.user.name }}
                      </td>
                    </tr>
                    <tr>
                      <th>Sponsor Phone</th>
                      <td>
                        {{ selectedPatient.user.phone }}
                      </td>
                    </tr>
                    <tr>
                      <th>Sponsor Email</th>
                      <td>
                        {{ selectedPatient.user.email }}
                      </td>
                    </tr>
                  </table>
                </div>

                <!-- Pairing patient (couple) account -->
                <div class="form-group" v-if="prescriptionForm.pairedPatient">
                  <div class="n-chk input-group-prepend">
                    <label
                      class="new-control new-checkbox new-checkbox-text checkbox-success"
                    >
                      <input
                        type="checkbox"
                        class="new-control-input"
                        v-model="prescriptionForm.pairPatient"
                      />
                      <span class="new-control-indicator"></span>
                      <span class="new-checkbox-content">
                        Pair Patient -
                        {{
                          `${prescriptionForm.pairedPatient.firstname} ${prescriptionForm.pairedPatient.lastname}`
                        }}?
                      </span>
                    </label>
                  </div>
                </div>

                <!-- Items for Selected Patient -->
                <div class="">
                  <div class="d-flex align-items-end justify-content-between">
                    <h5 class="font-weight-bold">
                      Items For
                      <span class="text-primary">
                        {{
                          `${selectedPatient.firstname} ${selectedPatient.lastname}`
                        }}
                      </span>
                    </h5>
                    <div>
                      <button
                        v-if="prescriptionForm.invoiceItemsCount > 1"
                        @click="removeInvoiceItem('invoiceItems')"
                        type="button"
                        class="btn btn-danger m-1"
                      >
                        <i class="fa fa-minus"></i>
                      </button>

                      <button
                        v-if="prescriptionForm.invoiceItemsCount < 7"
                        @click="addInvoiceItem('invoiceItems')"
                        type="button"
                        class="btn btn-success m-1"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th width="3%">#</th>
                          <th width="20%">Item</th>
                          <th width="20%">
                            Vendor
                            <b-btn
                              type="button"
                              variant="outline-dark"
                              class="p-0"
                              v-b-modal.vendor-form
                              v-b-tooltip.hover
                              title="Quickly add vendor"
                            >
                              <b-icon icon="plus"></b-icon>
                            </b-btn>
                          </th>
                          <th width="20%">Description</th>
                          <th width="15%">Gerocare Charges</th>
                          <th width="17%">Amount</th>
                          <th width="5%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            item, index
                          ) in prescriptionForm.invoiceItemsCount"
                          :key="item"
                        >
                          <td>{{ item }}</td>
                          <td>
                            <b-form-select
                              class="border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0"
                              :options="invoice_options"
                              text-field="name"
                              value-field="value"
                              v-model="
                                prescriptionForm.invoiceItems[index].name
                              "
                              size="sm"
                              plain
                            ></b-form-select>
                          </td>
                          <td>
                            <b-form-select
                              class="border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0"
                              :options="vendors"
                              text-field="name"
                              value-field="id"
                              v-model="
                                prescriptionForm.invoiceItems[index].vendor_id
                              "
                              size="sm"
                              plain
                            ></b-form-select>
                          </td>
                          <td>
                            <b-form-textarea
                              class="border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0"
                              v-model="
                                prescriptionForm.invoiceItems[index].description
                              "
                              size="sm"
                              rows="2"
                            ></b-form-textarea>
                          </td>
                          <td>
                            {{
                              prescriptionForm.invoiceItems[index].vat != ""
                                ? `${prescriptionForm.invoiceItems[index].vat}%`
                                : formatMoney(
                                    prescriptionForm.invoiceItems[index]
                                      .xtraamount
                                  )
                            }}
                          </td>
                          <td>
                            <money
                              class="form-control form-control-sm border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0 h-auto"
                              v-model="
                                prescriptionForm.invoiceItems[index].amount
                              "
                              v-bind="money"
                            ></money>
                          </td>
                          <td>
                            <button
                              v-if="prescriptionForm.invoiceItemsCount > 1"
                              @click="removeInvoiceItem('invoiceItems', index)"
                              type="button"
                              class="btn btn-sm btn-danger"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Items for Paired Patient -->
                <div class="" v-if="prescriptionForm.pairPatient">
                  <div class="d-flex align-items-end justify-content-between">
                    <h5 class="font-weight-bold">
                      Items For
                      <span class="text-primary">{{
                        `${prescriptionForm.pairedPatient.firstname} ${prescriptionForm.pairedPatient.lastname}`
                      }}</span>
                    </h5>
                    <div>
                      <button
                        v-if="prescriptionForm.pairInvoiceItemsCount > 1"
                        @click="removeInvoiceItem('pairInvoiceItems')"
                        type="button"
                        class="btn btn-danger m-1"
                      >
                        <i class="fa fa-minus"></i>
                      </button>

                      <button
                        v-if="prescriptionForm.pairInvoiceItemsCount < 7"
                        @click="addInvoiceItem('pairInvoiceItems')"
                        type="button"
                        class="btn btn-success m-1"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th width="3%">#</th>
                          <th width="20%">Item</th>
                          <th width="20%">
                            Vendor
                            <b-btn
                              type="button"
                              variant="outline-dark"
                              class="p-0"
                              v-b-modal.vendor-form
                              v-b-tooltip.hover
                              title="Quickly add vendor"
                            >
                              <b-icon icon="plus"></b-icon>
                            </b-btn>
                          </th>
                          <th width="20%">Description</th>
                          <th width="15%">Gerocare Charges</th>
                          <th width="17%">Amount</th>
                          <th width="5%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            item, index
                          ) in prescriptionForm.pairInvoiceItemsCount"
                          :key="item"
                        >
                          <td>{{ item }}</td>
                          <td>
                            <b-form-select
                              class="border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0"
                              :options="invoice_options"
                              text-field="name"
                              value-field="value"
                              v-model="
                                prescriptionForm.pairInvoiceItems[index].name
                              "
                              size="sm"
                              plain
                            ></b-form-select>
                          </td>
                          <td>
                            <b-form-select
                              class="border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0"
                              :options="vendors"
                              text-field="name"
                              value-field="id"
                              v-model="
                                prescriptionForm.pairInvoiceItems[index]
                                  .vendor_id
                              "
                              size="sm"
                              plain
                            ></b-form-select>
                          </td>
                          <td>
                            <b-form-textarea
                              class="border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0"
                              v-model="
                                prescriptionForm.pairInvoiceItems[index]
                                  .description
                              "
                              rows="2"
                              size="sm"
                            ></b-form-textarea>
                          </td>
                          <td>
                            {{
                              prescriptionForm.pairInvoiceItems[index].vat != ""
                                ? `${prescriptionForm.pairInvoiceItems[index].vat}%`
                                : formatMoney(
                                    prescriptionForm.pairInvoiceItems[index]
                                      .xtraamount
                                  )
                            }}
                          </td>
                          <td>
                            <money
                              class="form-control form-control-sm border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0 h-auto"
                              v-model="
                                prescriptionForm.pairInvoiceItems[index].amount
                              "
                              v-bind="money"
                            ></money>
                          </td>
                          <td>
                            <button
                              v-if="prescriptionForm.pairInvoiceItemsCount > 1"
                              @click="
                                removeInvoiceItem('pairInvoiceItems', index)
                              "
                              type="button"
                              class="btn btn-sm btn-danger"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Logistics Items -->
                <div class="">
                  <div class="d-flex align-items-end justify-content-between">
                    <h5 class="font-weight-bold">
                      Logistics for this Medical Request
                    </h5>
                    <div>
                      <button
                        v-if="prescriptionForm.logisticsItemsCount > 1"
                        @click="removeInvoiceItem('logisticsItems')"
                        type="button"
                        class="btn btn-danger m-1"
                      >
                        <i class="fa fa-minus"></i>
                      </button>

                      <button
                        v-if="prescriptionForm.logisticsItemsCount < 4"
                        @click="addInvoiceItem('logisticsItems')"
                        type="button"
                        class="btn btn-success m-1"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th width="3%">#</th>
                          <th width="20%">Item</th>
                          <th width="20%">
                            Vendor
                            <b-btn
                              type="button"
                              variant="outline-dark"
                              class="p-0"
                              v-b-modal.vendor-form
                              v-b-tooltip.hover
                              title="Quickly add vendor"
                            >
                              <b-icon icon="plus"></b-icon>
                            </b-btn>
                          </th>
                          <th width="20%">Description</th>
                          <th width="15%">Gerocare Charges</th>
                          <th width="17%">Amount</th>
                          <th width="5%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            item, index
                          ) in prescriptionForm.logisticsItemsCount"
                          :key="item"
                        >
                          <td>{{ item }}</td>
                          <td>
                            <b-form-select
                              class="border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0"
                              :options="logistics_options"
                              text-field="name"
                              value-field="value"
                              v-model="
                                prescriptionForm.logisticsItems[index].name
                              "
                              size="sm"
                              plain
                            ></b-form-select>
                          </td>
                          <td>
                            <b-form-select
                              class="border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0"
                              :options="vendors"
                              text-field="name"
                              value-field="id"
                              v-model="
                                prescriptionForm.logisticsItems[index].vendor_id
                              "
                              size="sm"
                              plain
                            ></b-form-select>
                          </td>
                          <td>
                            <b-form-textarea
                              class="border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0"
                              v-model="
                                prescriptionForm.logisticsItems[index]
                                  .description
                              "
                              rows="2"
                              size="sm"
                            ></b-form-textarea>
                          </td>
                          <td>
                            {{
                              prescriptionForm.logisticsItems[index].vat != ""
                                ? `${prescriptionForm.logisticsItems[index].vat}%`
                                : formatMoney(
                                    prescriptionForm.logisticsItems[index]
                                      .xtraamount
                                  )
                            }}
                          </td>
                          <td>
                            <money
                              class="form-control form-control-sm border-top-0 border-left-0 border-right-0 rounded-0 shadow-none p-0 h-auto"
                              v-model="
                                prescriptionForm.logisticsItems[index].amount
                              "
                              v-bind="money"
                            ></money>
                          </td>
                          <td>
                            <button
                              v-if="prescriptionForm.logisticsItemsCount > 1"
                              @click="
                                removeInvoiceItem('logisticsItems', index)
                              "
                              type="button"
                              class="btn btn-sm btn-danger"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Totals -->
                <div
                  class="mb-3 py-3 d-md-flex justify-content-between border-primary"
                  style="border-bottom: 5px double"
                >
                  <div>
                    <span class="text-muted">Total</span>
                    <h1 class="text-dark">{{ formatMoney(total) }}</h1>
                  </div>
                  <div>
                    <span class="text-muted">Overall Total</span>
                    <h1 class="text-dark">{{ formatMoney(overall_total) }}</h1>
                  </div>
                </div>

                <!-- Sponsor response -->
                <b-form-group
                  label="Sponsor Response"
                  label-for="sponsor_response"
                  :invalid-feedback="
                    prescriptionForm.errors.get('sponsor_response')
                  "
                  :state="!prescriptionForm.errors.has('sponsor_response')"
                >
                  <b-form-textarea
                    id="sponsor_response"
                    v-model="prescriptionForm.sponsor_response"
                    placeholder="Enter any comments from the sponsor"
                    :state="
                      prescriptionForm.errors.has('sponsor_response')
                        ? false
                        : null
                    "
                    rows="2"
                  ></b-form-textarea>
                </b-form-group>

                <!-- Doctor comment -->
                <b-form-group
                  label="Doctor Comment"
                  label-for="doctor_comment"
                  :invalid-feedback="
                    prescriptionForm.errors.get('doctor_comment')
                  "
                  :state="!prescriptionForm.errors.has('doctor_comment')"
                >
                  <b-form-textarea
                    id="doctor_comment"
                    v-model="prescriptionForm.doctor_comment"
                    placeholder="Enter any comments from the doctor"
                    :state="
                      prescriptionForm.errors.has('doctor_comment')
                        ? false
                        : null
                    "
                    rows="2"
                  ></b-form-textarea>
                </b-form-group>

                <!-- Bank options -->
                <b-form-group
                  label="Payment info to include on invoice"
                  v-slot="{ ariaDescribedby }"
                  :invalid-feedback="prescriptionForm.errors.get('banks')"
                  :state="!prescriptionForm.errors.has('banks')"
                >
                  <b-form-checkbox-group
                    id="bank-group"
                    v-model="prescriptionForm.banks"
                    :options="bankOptions"
                    :aria-describedby="ariaDescribedby"
                    name="banks"
                  ></b-form-checkbox-group>
                </b-form-group>

                <!-- Visits -->
                <b-form-group
                  label="Link Invoice to Visit"
                  label-for="visitrecord"
                  :invalid-feedback="prescriptionForm.errors.get('visit_id')"
                  :state="!prescriptionForm.errors.has('visit_id')"
                >
                  <b-form-select
                    id="visitrecord"
                    v-model="prescriptionForm.visit_id"
                    :state="
                      prescriptionForm.errors.has('visit_id') ? false : null
                    "
                    plain
                  >
                    <b-form-select-option
                      v-for="visit in visits"
                      :key="visit.id"
                      :value="visit.id"
                    >
                      Visit on {{ formatFancyDate(visit.appointment_date) }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <hr />

                <b-form-group :state="!prescriptionForm.errors.has('invoice')">
                  <b-form-checkbox
                    v-model="prescriptionForm.invoice"
                    size="lg"
                    switch
                  >
                    Do you want send the invoice email to the sponsor?
                    <span
                      :class="
                        'font-weight-bold text-' +
                        (prescriptionForm.invoice ? 'primary' : '')
                      "
                    >
                      {{ prescriptionForm.invoice ? "YES" : "NO" }}
                    </span>
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  size="lg"
                  variant="success"
                  @click="storePrescription({ forceSave: false })"
                  :disabled="prescriptionForm.busy"
                  block
                >
                  <b-spinner
                    label="Loading"
                    v-if="prescriptionForm.busy"
                    small
                  ></b-spinner>
                  Create Medical Request
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Wallet Debit -->
    <b-modal id="walletModal" centered>
      <div class="add-contact-box">
        <div class="add-contact-content">
          <div class="text-center row">
            <div class="col-12 align-self-end text-right">
              <p>
                <span class="text-muted">Overall Total:</span>
                <span class="h1">{{ formatMoney(base_total) }}</span>
              </p>
              <div style="border-top: 1px solid black"></div>
            </div>

            <div class="col-12 mt-4">
              <div class="text-center">
                <b-alert variant="primary" show>
                  Wallet Balance:
                  <b-badge variant="light" class="p-2">
                    {{ formatMoney(walletBalance) }}
                  </b-badge>
                </b-alert>

                <b-form-checkbox v-model="prescriptionForm.useWallet">
                  Debit from wallet
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>

        <b-button
          size="sm"
          variant="success"
          @click="storePrescription({ forceSave: true })"
          :disabled="prescriptionForm.busy"
        >
          <b-spinner
            label="Loading"
            v-if="prescriptionForm.busy"
            small
          ></b-spinner>
          Create Medical Request
        </b-button>
      </template>
    </b-modal>

    <!-- Vendor form -->
    <b-modal id="vendor-form" title="Add New Vendor" centered>
      <form
        method="POST"
        @submit.prevent="createVendor()"
        @keydown="vendorForm.onKeydown($event)"
      >
        <AlertError :form="vendorForm" />

        <b-form-group
          description=""
          label="Name *"
          label-for="name"
          :invalid-feedback="vendorForm.errors.get('name')"
          :state="!vendorForm.errors.has('name')"
        >
          <b-form-input
            id="name"
            v-model="vendorForm.name"
            placeholder="Enter the name of the vendor"
            type="text"
            size="sm"
            :state="vendorForm.errors.has('name') ? false : null"
            trim
          ></b-form-input>
        </b-form-group>

        <b-form-group
          description="E-mail address to enable us send notifications to"
          label="Email *"
          label-for="email"
          :invalid-feedback="vendorForm.errors.get('email')"
          :state="!vendorForm.errors.has('email')"
        >
          <b-form-input
            id="email"
            v-model="vendorForm.email"
            placeholder="Enter the email of the vendor"
            type="email"
            size="sm"
            :state="vendorForm.errors.has('email') ? false : null"
            trim
          ></b-form-input>
        </b-form-group>

        <b-form-group
          description="Telephone number"
          label="Phone number"
          label-for="phone_number"
          :invalid-feedback="vendorForm.errors.get('phone_number')"
          :state="!vendorForm.errors.has('phone_number')"
        >
          <b-form-input
            id="phone_number"
            v-model="vendorForm.phone_number"
            placeholder="Enter the phone number of the vendor"
            type="tel"
            size="sm"
            :state="vendorForm.errors.has('phone_number') ? false : null"
            trim
          ></b-form-input>
        </b-form-group>

        <b-form-group
          description=""
          label="Contact Name"
          label-for="contact_name"
          :invalid-feedback="vendorForm.errors.get('contact_name')"
          :state="!vendorForm.errors.has('contact_name')"
        >
          <b-form-input
            id="contact_name"
            v-model="vendorForm.contact_name"
            placeholder="Enter the contact person name of the vendor"
            type="text"
            size="sm"
            :state="vendorForm.errors.has('contact_name') ? false : null"
            trim
          ></b-form-input>
        </b-form-group>

        <b-form-group
          description="Telephone number to reach the vendor"
          label="Contact Phone number"
          label-for="contact_phone_number"
          :invalid-feedback="vendorForm.errors.get('contact_phone_number')"
          :state="!vendorForm.errors.has('contact_phone_number')"
        >
          <b-form-input
            id="contact_phone_number"
            v-model="vendorForm.contact_phone_number"
            placeholder="Enter the phone number of the contact person"
            type="tel"
            size="sm"
            :state="
              vendorForm.errors.has('contact_phone_number') ? false : null
            "
            trim
          ></b-form-input>
        </b-form-group>

        <b-form-group
          description="How should vendor receive payments?"
          label="Offer Type"
          label-for="type"
          :invalid-feedback="vendorForm.errors.get('type')"
          :state="!vendorForm.errors.has('type')"
        >
          <b-form-select
            id="type"
            v-model="vendorForm.type"
            :options="vendor_types"
            size="sm"
            :state="vendorForm.errors.has('type') ? false : null"
          >
            <template #first>
              <b-form-select-option value="" disabled>
                -- Please select an option --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </form>

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>

        <b-button
          size="sm"
          variant="success"
          @click="createVendor()"
          :disabled="vendorForm.busy"
        >
          <b-spinner label="Loading" v-if="vendorForm.busy" small></b-spinner>
          Create Vendor
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { gerohttp } from "@/utils/gerocarehttp";
import { http } from "@/utils/http";
import vselectPaginationSearch from "@/mixins/v-select/pagination-search";

import {
  Item,
  MoneyMask,
  InvoiceOptions,
  LogisticsOptions,
} from "@/models/invoice";

export default {
  name: "NewMedicalRequest",
  mixins: [vselectPaginationSearch],
  data() {
    return {
      patients: [],
      selectedPatient: null,
      prescriptionForm: new this.$Form({
        patient: {
          id: "",
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
        },
        doctor: {
          id: "",
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
        },
        sponsor: {
          id: "",
          firstname: "",
          lastname: "",
          email: "",
          country: "",
        },
        sponsor_response: "",
        doctor_comment: "",
        invoice: false, // generate invoice for the sponsor
        banks: ["ecobank", "zenith"],
        visit_id: null,
        useWallet: false,
        walletBalance: 0,
        invoiceItemsCount: 1,
        invoiceItems: [new Item()],
        logisticsItemsCount: 1,
        logisticsItems: [new Item()],
        pairPatient: false,
        pairedPatient: null,
        pairInvoiceItemsCount: 1,
        pairInvoiceItems: [new Item()],
      }),
      walletBalance: 0,
      money: new MoneyMask(),
      invoice_options: InvoiceOptions,
      logistics_options: LogisticsOptions,
      bankOptions: [
        { text: "ZENITH BANK OF NIGERIA", value: "zenith" },
        { text: "STERLING BANK PLC", value: "sterling" },
        { text: "ECOBANK PLC", value: "ecobank" },
      ],
      visits: [],
      vendors: [],
      vendorForm: this.$Form({
        name: "",
        email: "",
        phone_number: "",
        type: "",
        contact_name: "",
        contact_phone_number: "",
      }),
      vendor_types: [
        { value: "month_contract", text: "monthly contract" },
        { value: "oneoff", text: "one-off" },
      ],
    };
  },
  watch: {
    selectedPatient(val) {
      if (val) {
        this.patientSelected();
      }
    },
    "prescriptionForm.useWallet": {
      handler(val) {
        this.prescriptionForm.walletBalance = val
          ? this.selectedPatient?.user?.wallet
            ? this.selectedPatient.user.wallet.amount
            : 0
          : 0;
      },
      deep: true,
    },
    "prescriptionForm.invoiceItems": {
      handler() {
        this.calculateInvoiceItemsExtraCharges({
          forPair: false,
          forLogistics: false,
        });
      },
      deep: true,
    },
    "prescriptionForm.pairInvoiceItems": {
      handler() {
        this.calculateInvoiceItemsExtraCharges({
          forPair: true,
          forLogistics: false,
        });
      },
      deep: true,
    },
    "prescriptionForm.logisticsItems": {
      handler() {
        this.calculateInvoiceItemsExtraCharges({
          forPair: false,
          forLogistics: true,
        });
      },
      deep: true,
    },
  },
  computed: {
    total: function () {
      let total = 0;

      this.prescriptionForm.invoiceItems.forEach((item) => {
        total += Math.round(item.amount);
      });

      this.prescriptionForm.pairInvoiceItems.forEach((item) => {
        total += Math.round(item.amount);
      });

      this.prescriptionForm.logisticsItems.forEach((item) => {
        total += Math.round(item.amount);
      });

      return total;
    },
    overall_total: function () {
      let total = 0;

      this.prescriptionForm.invoiceItems.forEach((item) => {
        total += Number(
          item.vat
            ? Math.round(item.amount + (Number(item.vat) / 100) * item.amount)
            : Math.round(item.amount + item.xtraamount)
        );
      });

      this.prescriptionForm.pairInvoiceItems.forEach((item) => {
        total += Number(
          item.vat
            ? Math.round(item.amount + (Number(item.vat) / 100) * item.amount)
            : Math.round(item.amount + item.xtraamount)
        );
      });

      this.prescriptionForm.logisticsItems.forEach((item) => {
        total += Number(
          item.vat
            ? Math.round(item.amount + (Number(item.vat) / 100) * item.amount)
            : Math.round(item.amount + item.xtraamount)
        );
      });

      return total;
    },
    base_total: function () {
      let originalWalletBalance = this.selectedPatient?.user?.wallet
        ? this.selectedPatient.user.wallet.amount
        : 0;

      if (this.prescriptionForm.useWallet) {
        if (this.walletBalance >= this.overall_total) {
          this.walletBalance -= this.overall_total;
          return 0;
        }

        this.walletBalance = 0;
        return this.overall_total - originalWalletBalance;
      }

      this.walletBalance = originalWalletBalance;
      return this.overall_total;
    },
  },
  methods: {
    vselectComponentSearch(loading, vm) {
      gerohttp
        .get(geroendpoints.FETCH_PATIENTS, {
          params: {
            page: vm.vselectSearchPage,
            "filter[full_name]": vm.vselectSearchTerm,
            include: "couple",
          },
        })
        .then((response) => {
          vm.vselectSearchResults = response;
          vm.patients = response.data.map((patient) => {
            patient.fullname = `${patient.firstname} ${patient.lastname}`;

            return patient;
          });
        })
        .catch((error) => {
          vm.$toast.error(error);
        })
        .finally(() => {
          loading(false);
        });
    },
    vselectComponentClearSelection() {
      this.patients = [];
      this.selectedPatient = null;
      this.visits = [];
      this.prescriptionForm.reset();
    },
    patientSelected() {
      this.fillPrescriptionForm();
      this.fetchUncompletedVisitCycles();
    },
    fillPrescriptionForm() {
      this.prescriptionForm.reset();

      this.prescriptionForm.patient = {
        id: this.selectedPatient.id,
        firstname: this.selectedPatient.firstname,
        lastname: this.selectedPatient.lastname,
        email: this.selectedPatient.email,
        phone: this.selectedPatient.phone,
      };
      this.prescriptionForm.doctor = {
        id: this.selectedPatient.doctor.id,
        firstname: this.selectedPatient.doctor.firstname,
        lastname: this.selectedPatient.doctor.lastname,
        email: this.selectedPatient.doctor.email,
        phone: this.selectedPatient.doctor.phone,
      };
      this.prescriptionForm.sponsor = {
        id: this.selectedPatient.user.id,
        name: this.selectedPatient.user.name,
        email: this.selectedPatient.user.email,
        phone: this.selectedPatient.user.phone,
        country: this.selectedPatient.user.country
      };
      this.prescriptionForm.pairedPatient = this.selectedPatient?.couple
        ? {
            id: this.selectedPatient.couple.id,
            firstname: this.selectedPatient.couple.firstname,
            lastname: this.selectedPatient.couple.lastname,
            email: this.selectedPatient.couple.email,
            phone: this.selectedPatient.couple.phone,
          }
        : null;

      if (this.selectedPatient.user.wallet !== null) {
        this.walletBalance = this.selectedPatient.user.wallet.amount;
      }
    },
    fetchUncompletedVisitCycles() {
      http
        .get(
          endpoints.FETCH_UNCOMPLETE_VISIT_CYCLES.replace(
            ":id",
            this.selectedPatient.id
          )
        )
        .then((response) => {
          this.visits = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    addInvoiceItem(field) {
      this.prescriptionForm[field].push(new Item());
      this.prescriptionForm[field + "Count"] += 1;
    },
    removeInvoiceItem(field, index = null) {
      if (index !== null) {
        this.prescriptionForm[field].splice(index, 1);
      } else {
        this.prescriptionForm[field].pop();
      }

      this.prescriptionForm[field + "Count"] -= 1;
    },
    calculateInvoiceItemsExtraCharges({
      forPair = false,
      forLogistics = false,
    }) {
      if (!forLogistics) {
        this.prescriptionForm[
          forPair ? "pairInvoiceItems" : "invoiceItems"
        ].map((item) => {
          switch (item.name) {
            case "drugs":
            case "tests":
              item.vat = item.amount > 10000 ? 20 : 15;
              item.xtraamount = 0;
              break;
            case "physiotherapy":
            case "other":
              item.vat = 15;
              item.xtraamount = 0;
              break;
            case "specialist hospital visit":
              item.vat = 20;
              item.xtraamount = 0;
              break;
            case "specialist home review":
            case "specialist teleconsultation":
              item.vat = 0;
              item.xtraamount = 20000;
              break;
            case "caregiver inital assessment":
              item.vat = 0;
              item.xtraamount = 10000;
              break;
            case "caregiver monthly fee":
              item.vat = 20;
              item.xtraamount = 0;
              break;
          }

          return item;
        });
      } else {
        this.prescriptionForm.logisticsItems.map((item) => {
          switch (item.name) {
            case "pickup":
            case "drop-off":
            case "pickup and drop-off":
            case "home service":
            case "home delivery":
              item.vat = 15;
              item.xtraamount = 0;
              break;
          }

          return item;
        });
      }
    },
    storePrescription({ forceSave = false }) {
      if (this.validatePrescriptionForm()) {
        return;
      }

      if (
        this.selectedPatient.user.wallet != null &&
        this.selectedPatient.user.wallet.amount > 0 &&
        !forceSave
      ) {
        this.$bvModal.show("walletModal");

        return;
      }

      this.prescriptionForm
        .post(endpoints.ADD_PRESCRIPTION_RECORD)
        .then((response) => {
          this.$toast.success(response);
          this.$bvModal.hide("walletModal");
          this.$router.push({ name: "All Medical Requests" });
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    validatePrescriptionForm() {
      if (this.overall_total <= 1) {
        this.$toast.error("Kindly choose an item for the invoice");

        return true;
      }

      return false;
    },
    fetchVendors() {
      http
        .get(
          `${endpoints.VIEW_ALL_VENDORS}?fields[vendors]=id,name&do_not_paginate=1&filter[suspended]=0`
        )
        .then((response) => {
          this.vendors = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    createVendor() {
      this.vendorForm
        .post(endpoints.CREATE_VENDOR)
        .then((response) => {
          this.$toast.success(response);
          this.$toast.info(
            "Ensure you add the vendor's bank account details later on the Vendors page."
          );
          this.$bvModal.hide("vendor-form");
          this.vendorForm.reset();
          this.fetchVendors();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
  mounted() {
    this.fetchVendors();
  },
};
</script>
