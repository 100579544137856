export class Item {
  constructor() {
    (this.name = ""),
      (this.description = ""),
      (this.vat = 0), //gerocare charges actually
      (this.amount = 0),
      (this.xtraamount = 0),
      (this.vendor_id = "");
  }
}

export class MoneyMask {
  constructor() {
    (this.decimal = "."),
      (this.thousands = ","),
      (this.prefix = "NGN "),
      (this.suffix = ""),
      (this.precision = 2),
      (this.masked = false);
  }
}

export class PercentageMask {
  constructor() {
    (this.decimal = "."),
      (this.thousands = ","),
      (this.prefix = ""),
      (this.suffix = "%"),
      (this.precision = 1),
      (this.masked = false);
  }
}

export const InvoiceOptions = [
  {
    id: 1,
    name: "Drugs",
    value: "drugs",
  },
  {
    id: 2,
    name: "Tests",
    value: "tests",
  },
  {
    id: 3,
    name: "Physiotherapy",
    value: "physiotherapy",
  },
  {
    id: 4,
    name: "Specialist Home Review",
    value: "specialist home review",
  },
  {
    id: 9,
    name: "Specialist Hospital Visit",
    value: "specialist hospital visit",
  },
  {
    id: 5,
    name: "Specialist Teleconsultation",
    value: "specialist teleconsultation",
  },
  {
    id: 6,
    name: "Caregiver Inital Assessment",
    value: "caregiver inital assessment",
  },
  {
    id: 7,
    name: "Caregiver Monthly Fee",
    value: "caregiver monthly fee",
  },
  {
    id: 8,
    name: "Other",
    value: "other",
  },
];

export const LogisticsOptions = [
  {
    id: 1,
    name: "Pickup",
    value: "pickup",
  },
  {
    id: 2,
    name: "Drop-off",
    value: "drop-off",
  },
  {
    id: 3,
    name: "Pickup and Drop-off",
    value: "pickup and drop-off",
  },
  {
    id: 4,
    name: "Home Service",
    value: "home service",
  },
  {
    id: 5,
    name: "Home Delivery",
    value: "home delivery",
  },
];
